import React from 'react';
import { Check, ShoppingCart, AlertCircle, ChevronDown, ChevronUp, Eye, EyeOff } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

// Utility functions
const normalizeIngredientName = (name) => {
    return name;
};

const normalizeUnit = (unit) => {
    if (!unit) return 'unit';

    const unitMap = {
        'cups': 'cup',
        'tablespoons': 'tbsp',
        'teaspoons': 'tsp',
        'ounces': 'oz',
        'pounds': 'lb',
        'grams': 'g',
        'milliliters': 'ml',
        'liters': 'l'
    };

    return unitMap[unit.toLowerCase()] || unit;
};

const categorizeIngredient = (ingredient) => {
    const lowerIngredient = ingredient.toLowerCase();

    const categoryPatterns = {
        'Dairy & Eggs': /milk|cheese|cream|yogurt|butter|egg/,
        'Meat & Poultry': /beef|chicken|pork|lamb|turkey|duck|bacon|sausage/,
        'Seafood': /fish|salmon|tuna|shrimp|cod|prawn|crab|lobster/,
        'Vegetables': /lettuce|tomato|carrot|onion|garlic|potato|celery/,
        'Fruits': /apple|banana|orange|grape|berry|lemon|lime/,
        'Bakery': /bread|bun|roll|cake|cookie|pastry|pie/,
        'Pantry': /flour|sugar|rice|pasta|oil|vinegar|sauce/,
        'Spices & Seasonings': /salt|pepper|spice|herb|seasoning/,
        'Canned Goods': /canned|soup|broth|stock|beans/
    };

    for (const [category, pattern] of Object.entries(categoryPatterns)) {
        if (pattern.test(lowerIngredient)) {
            return category;
        }
    }

    return 'Other';
};

export const RecipeIngredients = ({ ingredients, useMetric, convertToMetric }) => {
    const [checkedIngredients, setCheckedIngredients] = React.useState(new Set());
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [isAddingToList, setIsAddingToList] = React.useState(false);
    const [addResult, setAddResult] = React.useState(null);
    const [expandedSpecials, setExpandedSpecials] = React.useState(new Set());
    const [showAllSpecials, setShowAllSpecials] = React.useState(false);

    // Count ingredients with special instructions
    const specialCount = ingredients.filter(ing => ing.special).length;

    React.useEffect(() => {
        const token = localStorage.getItem('recipeToken');
        setIsLoggedIn(!!token);
    }, []);

    const toggleIngredient = (index) => {
        setCheckedIngredients(prev => {
            const newChecked = new Set(prev);
            if (newChecked.has(index)) {
                newChecked.delete(index);
            } else {
                newChecked.add(index);
            }
            return newChecked;
        });
    };

    const toggleSpecial = (index) => {
        setExpandedSpecials(prev => {
            const newExpanded = new Set(prev);
            if (newExpanded.has(index)) {
                newExpanded.delete(index);
            } else {
                newExpanded.add(index);
            }
            return newExpanded;
        });
    };

    const toggleAllSpecials = () => {
        setShowAllSpecials(prev => !prev);
    };

    const addToShoppingList = async (ingredients) => {
        setIsAddingToList(true);
        setAddResult(null);

        try {
            const uncheckedIngredients = ingredients.filter((_, index) =>
                !checkedIngredients.has(index)
            );

            const shoppingItems = uncheckedIngredients.map(ing => ({
                name: normalizeIngredientName(ing.ingredient),
                quantity: parseFloat(ing.amount) || 1,
                unit: normalizeUnit(ing.unit),
                category: categorizeIngredient(ing.ingredient)
            }));

            const response = await fetch(`${API_URL}/shopping-list/bulk-add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('recipeToken')}`
                },
                body: JSON.stringify({ items: shoppingItems })
            });

            if (response.ok) {
                setAddResult({ success: true });
            } else {
                throw new Error('Failed to add items');
            }
        } catch (error) {
            console.error('Error adding to shopping list:', error);
            setAddResult({
                success: false,
                error: 'Failed to add items to shopping list'
            });
        } finally {
            setIsAddingToList(false);
        }
    };

    const renderIngredient = (ing, index) => {
        if (!ing.amount && !ing.unit) return ing.ingredient;

        let { amount, unit, special, ingredient } = ing;

        if (useMetric && unit) {
            ({ amount, unit } = convertToMetric(amount, unit));
        }

        const showSpecial = showAllSpecials || expandedSpecials.has(index);

        return (
            <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2">
                    <div className="font-medium text-blue-900 dark:text-blue-400">
                        {amount && <span className="tabular-nums">{amount} </span>}
                        {unit && <span>{unit}</span>}
                    </div>

                    <span className="text-gray-900 dark:text-gray-200 flex items-center gap-1">
                    {ingredient}
                        {special && !showSpecial && (
                            <div className="group relative inline-block">
                                <AlertCircle size={12} className="text-gray-400 dark:text-gray-500 flex-shrink-0" />
                                <div className="hidden md:group-hover:block absolute z-10 top-0 left-full ml-2 w-48">
                                    <div className="bg-gray-900 text-white text-sm rounded-lg shadow-lg p-2">
                                        {special}
                                    </div>
                                </div>
                            </div>
                        )}
                </span>
                </div>

                {special && (
                    <div className={`mt-1 text-sm text-gray-600 dark:text-gray-400 pl-4 border-l-2 border-gray-200 dark:border-gray-600 ${showSpecial ? 'block' : 'hidden'}`}>
                        {special}
                    </div>
                )}
            </div>
        );
    };

    const sortedIngredients = [...ingredients].sort((a, b) =>
        (a.step || 0) - (b.step || 0)
    );

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700">
            <div className="p-4 border-b border-gray-100 dark:border-gray-700">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Ingredients
                        </h3>
                        <span className="text-sm font-normal text-gray-400 dark:text-gray-300">
                            ({ingredients.length})
                        </span>
                    </div>

                    <div className="flex items-center gap-3">
                        {specialCount > 0 && (
                            <button
                                onClick={toggleAllSpecials}
                                className="flex items-center gap-1.5 px-2.5 py-1 text-sm rounded-md
                                    bg-gray-50 hover:bg-gray-100
                                    dark:bg-gray-800 dark:hover:bg-gray-700
                                    text-gray-500 dark:text-gray-400
                                    border border-gray-200 dark:border-gray-600
                                    transition-colors"
                            >
                                {showAllSpecials ? (
                                    <>
                                        <EyeOff size={14}/>
                                        <span>Hide</span>
                                    </>
                                ) : (
                                    <>
                                        <Eye size={14}/>
                                        <span>Show Extra ({specialCount})</span>
                                    </>
                                )}
                            </button>
                        )}

                        {isLoggedIn && (
                            <button
                                onClick={() => addToShoppingList(ingredients)}
                                disabled={isAddingToList}
                                className="
                                    print:hidden
                                    flex items-center gap-2 px-4 py-2 rounded-lg font-medium
                                    bg-cyan-600 hover:bg-cyan-700
                                    disabled:bg-cyan-400 disabled:cursor-not-allowed
                                    text-white
                                    transform transition-all duration-200
                                    hover:scale-105 active:scale-100
                                    dark:bg-slate-700 dark:hover:bg-slate-600
                                "
                            >
                                <ShoppingCart className={`h-4 w-4 ${isAddingToList ? 'animate-pulse' : ''}`}/>
                                <span>
                                    {isAddingToList ? 'Adding...' : 'Add to List'}
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {addResult && (
                <div className={`mx-4 mt-4 p-4 rounded-lg ${
                    addResult.success
                        ? 'bg-green-50 text-green-800 dark:bg-green-900/50 dark:text-green-100'
                        : 'bg-red-50 text-red-800 dark:bg-red-900/50 dark:text-red-100'
                }`}>
                    {addResult.success
                        ? 'Items added to shopping list successfully!'
                        : addResult.error
                    }
                </div>
            )}

            <ul className="divide-y divide-gray-100 dark:divide-gray-700">
                {sortedIngredients.map((ing, index) => (
                    <li key={index}>
                        <label
                            className="flex items-start gap-3 p-3 hover:bg-gray-50 dark:hover:bg-gray-600/50 transition-colors cursor-pointer group rounded-lg">
                            <div className="flex-shrink-0 pt-1">
                                <div
                                    onClick={() => toggleIngredient(index)}
                                    className={`
                                        w-5 h-5 rounded border transition-all duration-200 
                                        flex items-center justify-center
                                        ${checkedIngredients.has(index)
                                        ? 'bg-gradient-to-r from-cyan-500 to-blue-500 border-transparent'
                                        : 'border-gray-300 dark:border-gray-500 group-hover:border-gray-400 dark:group-hover:border-gray-400'
                                    }
                                    `}
                                >
                                    {checkedIngredients.has(index) && (
                                        <Check size={14} className="text-white"/>
                                    )}
                                </div>
                            </div>

                            <input
                                type="checkbox"
                                className="sr-only"
                                checked={checkedIngredients.has(index)}
                                onChange={() => toggleIngredient(index)}
                            />

                            <span className={`
                                flex-grow leading-relaxed
                                ${checkedIngredients.has(index)
                                ? 'text-gray-400 line-through dark:text-gray-500'
                                : 'text-gray-700 dark:text-gray-300'
                            }
                                transition-colors duration-200
                            `}>
                                {renderIngredient(ing, index)}
                            </span>
                        </label>
                    </li>
                ))}
            </ul>

            {checkedIngredients.size > 0 && (
                <div
                    className="p-3 bg-gray-50 dark:bg-gray-700/50 border-t border-gray-200 dark:border-gray-600 text-sm text-gray-500 dark:text-gray-300">
                    {checkedIngredients.size} of {ingredients.length} ingredients checked
                </div>
            )}
        </div>
    );
};

export default RecipeIngredients;