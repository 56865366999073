import React from 'react';
import { Clock } from 'lucide-react';

const foodEmojis = ['🍝', '🍕', '🥘', '🍜', '🥗', '🍲', '🥪', '🌮', '🍣', '🥟'];

const RandomRecipes = ({ recipes, imagesDomainPath }) => {
    const getRelativeTime = (timestamp) => {
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        const now = new Date();
        const diff = new Date(timestamp) - now;
        const minutes = Math.round(diff / 1000 / 60);
        const hours = Math.round(minutes / 60);
        const days = Math.round(hours / 24);

        if (Math.abs(minutes) < 60) return rtf.format(minutes, 'minute');
        if (Math.abs(hours) < 24) return rtf.format(hours, 'hour');
        return rtf.format(days, 'day');
    };

    return (
        <div className="mt-8 mb-6 print:hidden">
            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                Recipes saved by others
            </h3>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
                {recipes.map((recipe, index) => (
                    <a
                        key={index}
                        href={`/recipe/${recipe._id}`}
                        className="group block bg-gray-50 dark:bg-gray-900 rounded-lg overflow-hidden
                        hover:shadow-lg transition-all duration-300 border border-gray-200
                        dark:border-gray-800 cursor-pointer"
                    >
                        <div className="aspect-[4/1] overflow-hidden bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
                            {recipe.ogImage ? (
                                <img
                                    src={`${imagesDomainPath}/${recipe.ogImage.replace(/\\/g, '/')}`}
                                    alt={recipe.title}
                                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                                />
                            ) : (
                                <span className="text-6xl opacity-40 group-hover:opacity-100 transition-all duration-300 filter saturate-0 group-hover:saturate-100">
                                    {foodEmojis[index % foodEmojis.length]}
                                </span>
                            )}
                        </div>

                        <div className="p-3">
                            <h4 className="font-medium text-base text-gray-900 dark:text-gray-100 line-clamp-1">
                                {recipe.title}
                            </h4>

                            <div className="flex items-center mt-2 text-sm text-gray-500 dark:text-gray-400">
                                <Clock size={14} className="mr-1.5"/>
                                <span>{getRelativeTime(recipe.scannedAt)}</span>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default RandomRecipes;