import React, {useState, useEffect, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Navigation from './navigation';
import { ChefHat } from 'lucide-react';
import ThemeToggle from "./themeToggle";
import UserContext from "./UserContext";

const API_URL = process.env.REACT_APP_API_URL;

const MainLayout = ({ children }) => {
    const {
        user,
        setUser,
        remainingRequests,
        setRemainingRequests,
        isDark,
        setIsDark,
        handleLogout,
        showGoogleLogin,
        setShowGoogleLogin,
    } = useContext(UserContext);

    // Effect to handle dark mode
    useEffect(() => {
        if (isDark) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('darkMode', JSON.stringify(isDark));
    }, [isDark]);

    const toggleDarkMode = () => {
        setIsDark(!isDark);
    };



    const handleLoginSuccess = async (credentialResponse) => {
        try {
            console.log('Credential Response:', credentialResponse); // Add this line

            const response = await axios.post(`${API_URL}/auth/google`, {
                credential: credentialResponse.credential,
            });

            const { token, user: userData, remainingRequests: requests } = response.data;
            localStorage.setItem('recipeToken', token);
            setUser(userData);
            setRemainingRequests(requests);
            setShowGoogleLogin(false);
        } catch (err) {
            console.error('Authentication failed:', err);
            alert('Login failed. Please try again.');
        }
    };

    return (
            <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors">
            {/* Simple Header */}
            <header className="h-14 border-b border-gray-200 dark:border-gray-800">
                <div className="h-full mx-auto px-4">
                    <div className="h-full flex items-center justify-between gap-8">
                        {/* Logo - Simple and clean */}
                        <Link to="/" className="flex items-center gap-3 select-none">
                            <ChefHat className="h-8 w-8 text-cyan-600 dark:text-cyan-500"/>
                            <div className="flex items-baseline">
                                <h1 className="text-2xl font-semibold text-gray-800 dark:text-white">
                                    Recipe Extractor
                                    <span
                                        className="ml-2 text-xs px-2 py-0.5 font-medium bg-cyan-50 text-cyan-600 dark:bg-cyan-900/30 dark:text-cyan-400 rounded-full border border-cyan-100 dark:border-cyan-800">
                beta
            </span>
                                </h1>
                            </div>
                        </Link>

                        {/* Navigation and Theme Toggle */}
                        <div className="flex items-center gap-4">
                            <ThemeToggle isDark={isDark} onToggle={toggleDarkMode}/>
                            <Navigation
                                user={user}
                                onLogin={() => setShowGoogleLogin(true)}
                                onLogout={handleLogout}
                            />
                        </div>
                    </div>
                </div>
            </header>

            {/* User Info - Subtle but clear */}
            {user && (
                <div
                    className="h-10 bg-gray-50 dark:bg-gray-800/50 border-b border-gray-200 dark:border-gray-800 print:hidden">
                    <div className="h-full mx-auto px-4 flex items-center justify-between">
                        <div className="text-sm text-gray-600 dark:text-gray-300">
                            {/* Displaying the username */}
                            Hello, {user.email}!
                        </div>
                        <div className="text-sm text-gray-600 dark:text-gray-300">
                            {remainingRequests} extractions remaining
                        </div>
                    </div>
                </div>
            )}

            {/* Main Content - Clean and focused */}
            <main className="max-w-[1800px] mx-auto px-4 py-8">
                {children}
            </main>

            {/* Footer - Minimal */}
            <footer
                className="w-full h-12 border-t border-gray-200 dark:border-gray-800 print:hidden bg-white dark:bg-gray-900">
                <div className="h-full max-w-[1800px] mx-auto px-4">
                    <div className="h-full flex items-center justify-center gap-6 text-sm">
                        <Link
                            to="/privacy-policy"
                            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        >
                            Privacy
                        </Link>
                        <Link
                            to="/cookie-policy"
                            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                        >
                            Cookies
                        </Link>
                        <span className="text-gray-400 dark:text-gray-500">v1.1.1</span>
                    </div>
                </div>
            </footer>


                {/* Login Modal - Simple and focused */}
                {showGoogleLogin && (
                    <div
                        className="fixed inset-0 bg-white/90 dark:bg-gray-900/90 flex items-center justify-center z-50"
                        onClick={() => setShowGoogleLogin(false)}
                    >
                        <div
                            className="w-full max-w-sm p-8 bg-white dark:bg-gray-800 shadow-lg rounded"
                            onClick={e => e.stopPropagation()}
                        >
                            <div className="text-center mb-6">
                                <h2 className="text-lg text-gray-900 dark:text-white mb-2">
                                    Sign in
                                </h2>
                                <p className="text-sm text-gray-600 dark:text-gray-300">
                                to continue to Recipe Extractor
                            </p>
                        </div>

                        <div className="flex justify-center mb-6">
                            <GoogleLogin
                                onSuccess={handleLoginSuccess}
                                onError={() => {
                                    console.error('Login Failed');
                                    setShowGoogleLogin(false);
                                }}
                                useOneTap
                                shape="circle"
                                theme={isDark ? "filled_black" : "outline"}
                            />
                        </div>

                        <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                            By continuing, you agree to our{' '}
                            <Link to="/privacy-policy" className="text-blue-600 dark:text-blue-400 hover:underline">
                                Privacy Policy
                            </Link>
                        </p>
                    </div>
                </div>
            )}
            </div>
    );
};
export default MainLayout;