import React from 'react';
import { X as CloseIcon } from 'lucide-react';

const foodEmojis = ['🍝', '🍕', '🥘', '🍜', '🥗', '🍲', '🥪', '🌮', '🍣', '🥟'];

const HistoryRecipes = ({ history, handleHistoryClick, removeFromHistory, imagesDomainPath }) => {
    return (
        <div className="mt-8 mb-6 print:hidden">
            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                Recent Recipes
            </h3>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
                {history.map((historyItem, index) => (
                    <div
                        key={index}
                        className="group block bg-gray-50 dark:bg-gray-900 rounded-lg overflow-hidden
                        hover:shadow-lg transition-all duration-300 border border-gray-200
                        dark:border-gray-800 relative"
                    >
                        <button
                            onClick={() => removeFromHistory(historyItem.url)}
                            className="absolute right-2 top-2 p-1.5 bg-gray-900/10 hover:bg-gray-900/20
                            dark:bg-gray-700/50 dark:hover:bg-gray-700 rounded-full z-10 opacity-0
                            group-hover:opacity-100 transition-opacity duration-200"
                            aria-label="Remove from history"
                        >
                            <CloseIcon size={14} className="text-gray-700 dark:text-gray-300" />
                        </button>

                        <div
                            onClick={() => handleHistoryClick(historyItem)}
                            className="cursor-pointer"
                        >
                            <div className="aspect-[3/2] overflow-hidden bg-gray-100 dark:bg-gray-800 flex items-center justify-center">
                                {historyItem.recipe.ogImage ? (
                                    <img
                                        src={`${imagesDomainPath}/${historyItem.recipe.ogImage.replace(/\\\\/g, '/')}`}
                                        alt={historyItem.title}
                                        className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                                    />
                                ) : (
                                    <span className="text-6xl opacity-40 group-hover:opacity-100 transition-all duration-300 filter saturate-0 group-hover:saturate-100">
                                        {foodEmojis[index % foodEmojis.length]}
                                    </span>
                                )}
                            </div>

                            <div className="p-3">
                                <h4 className="font-medium text-base text-gray-900 dark:text-gray-100 line-clamp-1">
                                    {historyItem.title}
                                </h4>
                                <div className="text-sm text-gray-500 dark:text-gray-400 line-clamp-1 mt-2">
                                    {historyItem.url}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HistoryRecipes;